.blurb {
    margin-left: 8px;
}

.blurb h1 {
    margin-bottom: 0;
    margin-top: 10px;
}

.blurb h3 {
    margin-top: 5px;
    margin-bottom: 5px;
}
