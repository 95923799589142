@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro');

body {
    font-family: "Anonymous Pro", monospace;
    font-size: 100%;
    background-color: #2B3847;
    color: #f3f1f3;
    line-height: 150%;
    display: flex;
    justify-content: center;
    margin: 0;
}
